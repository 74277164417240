import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Button, Container, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent to:', email);
      setSuccess(true);
      setTimeout(() => {
        navigate('/login'); // Redirect to the login page
      }, 3000); // Wait for 3 seconds before redirecting
    } catch (error) {
      console.error('Reset password error:', error.message);
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        {success && (
          <Typography variant="body2" color="success.main">
            Password reset email sent successfully. Redirecting to login...
          </Typography>
        )}
        <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send Reset Email
          </Button>
        </Box>
        <Typography variant="body2">
          Remember your password?{' '}
          <Link to="/login" style={{ textDecoration: 'none' }}>
            Login here
          </Link>
          .
        </Typography>
      </Box>
    </Container>
  );
};

export default ResetPassword;