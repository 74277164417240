import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase'; // Import auth from firebase.js
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Button, Container, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Logged in:', email);
      setLoginSuccess(true);
      setTimeout(() => {
        navigate('/'); // Redirect to the home page after 3 seconds
      }, 3000);
    } catch (error) {
      console.error('Login error:', error.message);
      setError('Wrong credentials');
    }
  };

  return (
    <Container maxWidth="xs">
      {loginSuccess ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" color="success.main">
            You have successfully logged in. Redirecting...
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
            justifyContent: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                style: {
                  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
                    },
                  },
                },
                '& .MuiInputLabel-outlined': {
                  '&.Mui-focused': {
                    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                  },
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                style: {
                  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
                    },
                  },
                },
                '& .MuiInputLabel-outlined': {
                  '&.Mui-focused': {
                    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                  },
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                '&:hover': {
                  backgroundColor: 'rgba(100, 100, 100, 0.5)',
                  // Reset on touch devices, it doesn't add specificity
                  '@media (hover: none)': {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                  },
                },
              }}
            >
              Login
            </Button>
          </Box>
          <Typography variant="body2">
            (Forgot password?{' '}
            <Link to="/resetpassword" style={{ textDecoration: 'none' }}>
              Reset here)
            </Link>
          </Typography>
          <Typography variant="body2">
            (If you haven't created an account,{' '}
            <Link to="/register" style={{ textDecoration: 'none' }}>
              make one here)
            </Link>
            .
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default LoginPage;