import {
  Box,
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Item from "../../components/Item";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { addToCart } from "../../state";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const ItemDetails = () => {
  const dispatch = useDispatch();
  const { itemId } = useParams();
  const [value, setValue] = useState("description");
  const [count, setCount] = useState(1);
  const [size, setSize] = useState("");
  const [item, setItem] = useState(null);
  const [items, setItems] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  async function getItem() {
    const item = await fetch(
      `https://strapi8ball123anim888-production.up.railway.app/api/items/${itemId}?populate=image`,
      {
        method: "GET",
      }
    );
    const itemJson = await item.json();
    setItem(itemJson.data);
  }

  async function getItems() {
    const items = await fetch(
      `https://strapi8ball123anim888-production.up.railway.app/api/items?populate=image`,
      {
        method: "GET",
      }
    );
    const itemsJson = await items.json();
    setItems(itemsJson.data);
  }

  useEffect(() => {
    getItem();
    getItems();
  }, [itemId]); // eslint-disable-line react-hooks/exhaustive-deps

  const theme = useTheme();
  const possibleSizes = ["S", "M", "L", "XL"];

  return (
    <Box width="80%" m="80px auto">
      <Box display="flex" flexWrap="wrap" columnGap="40px" mb="40px">
        {/* IMAGES */}
        <Box flex="1 1 40%">
          <img
            alt={item?.name}
            width="100%"
            height="100%"
            src={`https://strapi8ball123anim888-production.up.railway.app${item?.attributes?.image?.data?.attributes?.formats?.medium?.url}`}
            style={{ objectFit: "contain" }}
          />
        </Box>

          {/* ACTIONS */}
          <Box flex="1 1 50%">
            <Box m="0px 0 25px 0">
              <Typography variant="h3">{item?.attributes?.name}</Typography>
              <Typography>${item?.attributes?.price}</Typography>
              <Typography sx={{ mt: "20px" }}>
                {item?.attributes?.longDescription}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" minHeight="50px" mb="20px">
            <Box sx={{ height: '40px' }}
              display="flex"
              alignItems="center"
              border="1.5px solid black"
              borderRadius="20px"
              boxShadow={
                theme.palette.mode === "dark"
                  ? "0px 0px 8px rgba(250, 250, 250, 0.2)"
                  : "0px 0px 8px rgba(128, 128, 128, 0.7)"
              }
              mr="10px"
              p="2px 5px"
              >
              <IconButton
                onClick={() => setCount(Math.max(count - 1, 1))}
                color={theme.palette.mode === "dark" ? "inherit" : "default"}
              >
                <RemoveIcon />
              </IconButton>
              <Typography sx={{ p: "0 5px" }}>{count}</Typography>
              <IconButton
                onClick={() => setCount(count + 1)}
                color={theme.palette.mode === "dark" ? "inherit" : "default"}
              >
                <AddIcon />
              </IconButton>
            </Box>
            <Select 
              value={size}
              onChange={handleSizeChange}
              displayEmpty
              sx={{
                height: '40px',
                borderRadius: 2,
                '& .MuiSelect-icon': {
                  color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                },
              }}
            >
              <MenuItem value="" disabled>
                Choose Size
              </MenuItem>
              {possibleSizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
            <div style={{ marginRight: '10px' }} />
            <Button
              sx={{
                height: '40px',
                backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#222222',
                color: 'white',
                borderRadius: 5,
                padding: '10px 30px',
                transition: '0.3s',
                boxShadow: theme.palette.mode === 'dark'
                  ? '0px 0px 8px rgba(255, 255, 255, 0.2)'
                  : '0px 0px 8px rgba(128, 128, 128, 0.2)',
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark' ? '#555' : 'rgba(0, 0, 0, 0.1)',
                },
              }}
              onClick={() => {
                if (size === '') {
                  alert('Please choose a size.');
                } else {
                  dispatch(addToCart({ item: { ...item, count }, size }));
                }
              }}
            >
              ADD TO CART
            </Button>
          </Box>  
        </Box> 
      </Box>

      {/* TABS */}
      <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor={theme.palette.mode === "dark" ? "white" : theme.palette.secondary.main}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor:
              theme.palette.mode === "dark" ? "white" : theme.palette.secondary.main,
          },
        }}
      >
          <Tab label="Details" value="details" />
          <Tab label="Reviews" value="reviews" />
        </Tabs>
        {value === "details" && (
          <Typography sx={{ mt: "20px" }}>
            {item?.attributes?.longDescription}
          </Typography>
        )}
        {value === "reviews" && (
          <Typography sx={{ mt: "20px" }}>Reviews coming soon!</Typography>
        )}
      </Box>

      {/* RELATED ITEMS */}
      <Box>
      <Typography variant="h4" sx={{ mt: "40px", mb: "20px" }}>
        You may also like
      </Typography>
      <Grid container spacing={2}>
        {items
          .filter((relatedItem) => relatedItem.id !== parseInt(itemId, 10))
          .slice(0, 4)
          .map((item) => (
            <Grid item key={item.id} xs={12} sm={6} md={3}>
              <Item item={item} />
            </Grid>
          ))}
      </Grid>
    </Box>
    </Box>
  );
};

export default ItemDetails;