import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider, CssBaseline, Box } from '@mui/material';
import Home from "./scenes/home/Home";
import Navbar from "./scenes/global/Navbar";
import Footer from "./scenes/footer/Footer";
import ItemDetails from "./scenes/itemDetails/ItemDetails";
import CartMenu from "./scenes/global/CartMenu";
import Checkout from "./scenes/checkout/Checkout";
import Confirmation from "./scenes/checkout/Confirmation";
import LoginPage from "./scenes/global/LoginPage";
import RegisterPage from "./scenes/global/RegisterPage";
import ResetPassword from "./scenes/global/ResetPassword";
import Profile from "./scenes/global/Profile";
import ProfileReset from "./scenes/global/ProfileResetPassword";
import { theme as lightTheme, shades } from './theme';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedPreference = localStorage.getItem('darkMode');
    return savedPreference !== null ? JSON.parse(savedPreference) : false;
  });
  const toggleDarkMode = () => {
    const isDarkModeEnabled = !darkMode;
    setDarkMode(isDarkModeEnabled);
    localStorage.setItem('darkMode', isDarkModeEnabled);
  };
  const darkTheme = createTheme({
    ...lightTheme,
    palette: {
      ...lightTheme.palette,
      mode: 'dark',
      text: {
        primary: shades.neutral[100],
        secondary: shades.neutral[200],
        disabled: shades.neutral[600],
      },
      background: {
        default: '#121212',
      },
    },
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Box
        className="app"
        sx={{
          transition: 'background-color 0.5s ease'
        }}
      >
        <BrowserRouter>
          <Navbar onToggleDarkMode={toggleDarkMode} darkMode={darkMode} />
          <ScrollToTop />
          <Routes>
            <Route path="/profilereset" element={<ProfileReset />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/" element={<Home />} />
            <Route path="item/:itemId" element={<ItemDetails />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="checkout/success" element={<Confirmation />} />
          </Routes>
          <CartMenu />
          <Footer />
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}

export default App;