import { Box, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";

function Footer() {
  const openLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box
      padding="60px 5%"
      marginTop="50px"
      bgcolor="black"
      color="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h6">Our Store</Typography>
        <Typography>About Us</Typography>
        <Typography>Contact</Typography>
        <Typography>Shipping & Returns</Typography>
        <Typography>Privacy Policy</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop="50px"
      >
        <InstagramIcon
          sx={{
            fontSize: 30,
            marginRight: 1.5,
            cursor: "pointer",
            transition: "color 0.3s",
            "&:hover": {
              color: "purple",
            },
          }}
          onClick={() => openLink("https://instagram.com/xo_pt1anim/")}
        />

        <GoogleIcon
          sx={{
            fontSize: 30,
            marginRight: 1.5,
            cursor: "pointer",
            transition: "color 0.3s",
            "&:hover": {
              color: "#4285F4",
            },
          }}
          onClick={() => openLink("https://pay.google.com/")}
        />

        <TwitterIcon
          sx={{
            fontSize: 30,
            marginRight: 1.5,
            cursor: "pointer",
            transition: "color 0.3s",
            "&:hover": {
              color: "#89CFF0",
            },
          }}
          onClick={() => openLink("https://twitter.com/stussy")}
        />
      </Box>
      <Box marginTop="30px" textAlign="center">
        <Typography variant="subtitle2">
          &copy; {new Date().getFullYear()} Our Store. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;