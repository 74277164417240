import ShoppingList from "./ShoppingList";
import MainCarousel from "./MainCarousel";

function Home() {
  return (
    <div className="home">
      <MainCarousel />
      <ShoppingList id="shopping-list" />
    </div>
  );
}

export default Home;
