import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Item from "../../components/Item";
import { Typography, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { setItems } from "../../state";
import { useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import { motion } from "framer-motion"; // Import motion
import { InView } from "react-intersection-observer"; // Import InView

const ShoppingList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = useState("all");
  const items = useSelector((state) => state.cart.items);
  const breakPoint = useMediaQuery("(min-width:600px)");
  const [searchTerm, setSearchTerm] = useState("");

  const itemVariants = {
    hidden: { scale: 0.5, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  const itemAnimation = (inView) => ({
    initial: inView ? "visible" : "hidden",
    animate: inView ? "visible" : "hidden",
    variants: itemVariants,
    transition: { duration: 0.5, ease: "easeOut" },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log("Search term changed:", event.target.value);
  };

  async function getItems() {
    try {
      const items = await fetch(
        "https://strapi8ball123anim888-production.up.railway.app/api/items?populate=image",
        { method: "GET" }
      );
      const itemsJson = await items.json();
      dispatch(setItems(itemsJson.data));
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getItems();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredItems = (itemList) => {
    const searchWords = searchTerm.toLowerCase().trim().split(" ");
  
    const result =
      searchTerm === ""
        ? itemList
        : itemList.filter((item) =>
            searchWords.some((word) =>
              item.attributes.name.toLowerCase().includes(word)
            )
          );
  
    return result;
  };

  const knitsItems = items ? items.filter(
    (item) => item.attributes.category === "knits"
  ) : [];
  const pantsItems = items ? items.filter(
    (item) => item.attributes.category === "pants"  
  ) : [];
  const teesItems = items ? items.filter(
    (item) => item.attributes.category === "tees" 
  ) : [];

  return (
    <Box width="80%" margin="60px auto">
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
      <TextField
        className="custom-search-input"
        label="Search"
        variant="filled"
        size="medium"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search
                style={{
                  color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                }}
              />
            </InputAdornment>
          ),
          style: {
            color: theme.palette.mode === "dark" ? "#ffffff" : "inherit",
          },
        }}
        sx={{
          backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[900] : "rgba(255, 255, 255, 0.9)",
          borderRadius: "1px",
          zIndex: 2,

          '& .MuiInputLabel-filled': {
            top: '-5px',
            color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "inherit",
            '&.Mui-focused': {
              color: theme.palette.mode === "dark" ? "#ffffff" : "inherit",
            },
          },
          '& .MuiFilledInput-input': {
            height: 'inherit',
            lineHeight: 'inherit',
            paddingTop: '15px',
          },
          '& .MuiFilledInput-root': {
            borderRadius: "1px",
            position: 'relative',
            '&:before': {
              content: '""',
            },
            '&:after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: '10%',
              width: '0%',
              borderBottom: 'none', // Set borderBottom to none
            },
            '&.Mui-focused': {
              backgroundColor: theme.palette.mode === "dark" ? "rgba(18, 18, 18, 0.9)" : "rgba(255, 255, 255, 0.9)",
              '&:after': {
                borderBottom: theme.palette.mode === "dark" 
                  ? '2px solid white'  // White line for dark mode when focused
                  : '2px solid black',  // Black line for light mode when focused
                left: '0%',
                width: '100%',
              },
            },
          },
        }}
      />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ maxWidth: "80%" }}>
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          centered
          TabIndicatorProps={{
            sx: {
              display: breakPoint ? "block" : "none",
              backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.primary.main,
            },
          }}
          sx={{
            m: "25px",
            borderRadius: "5px",
            boxShadow:
              theme.palette.mode === "dark"
                ? "0 3px 5px rgba(255, 255, 255, 0.2)"
                : "0 3px 5px rgba(0, 0, 0, 0.2)",
            backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[900] : "inherit", // Update this line
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
            "& .MuiTab-root": {
              padding: "8px 16px",
              fontSize: ".9rem",
              fontWeight: "none",
              textTransform: "uppercase",
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
              "&.Mui-selected": {
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? "#ffffff"
                    : theme.palette.primary.main,
              },
            },
          }}
        >
        <Tab
          label="ALL"
          value="all"
          sx={{
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        />
        <Tab
          label="PANTS"
          value="pants"
          sx={{
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        />
        <Tab
          label="TEES"
          value="tees"
          sx={{
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        />
        <Tab
          label="KNITS"
          value="knits"
          sx={{
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        />
      </Tabs>
      </Box>
    </Box>
      <Box
        margin="0 auto"
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          '& > *': {
            marginX: '1.33%',
            marginBottom: '20px',
          },
        }}
      >
        {value === "all" &&
          filteredItems(items).map((item) => (
            <InView key={`${item.name}-${item.id}`} triggerOnce>
              {({ inView, ref }) => (
                <motion.div ref={ref} {...itemAnimation(inView)}>
                  <Item item={item} />
                </motion.div>
              )}
            </InView>
          ))}
        {value === "pants" &&
          filteredItems(pantsItems).map((item) => (
            <InView key={`${item.name}-${item.id}`} triggerOnce>
              {({ inView, ref }) => (
                <motion.div ref={ref} {...itemAnimation(inView)}>
                  <Item item={item} />
                </motion.div>
              )}
            </InView>
          ))}
        {value === "tees" &&
          filteredItems(teesItems).map((item) => (
            <InView key={`${item.name}-${item.id}`} triggerOnce>
              {({ inView, ref }) => (
                <motion.div ref={ref} {...itemAnimation(inView)}>
                  <Item item={item} />
                </motion.div>
              )}
            </InView>
          ))}
        {value === "knits" &&
          filteredItems(knitsItems).map((item) => (
            <InView key={`${item.name}-${item.id}`} triggerOnce>
              {({ inView, ref }) => (
                <motion.div ref={ref} {...itemAnimation(inView)}>
                  <Item item={item} />
                </motion.div>
              )}
            </InView>
          ))}
        {((value === "all" && filteredItems(items).length === 0) ||
        (value === "pants" && filteredItems(pantsItems).length === 0) ||
        (value === "tees" && filteredItems(teesItems).length === 0) ||
        (value === "knits" && filteredItems(knitsItems).length === 0)) && (
        <Typography variant="h6" textAlign="center" gridColumn="1/ -1">
        No search results found.
        </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ShoppingList;