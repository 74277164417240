import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { shades } from "../../theme";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
  updateItemSize,
} from "../../state";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);
  const [showSizeWarning, setShowSizeWarning] = useState(false);
  const [showEmptyCartWarning, setShowEmptyCartWarning] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const ItemImage = styled.img`
  width: 123px;
  height: 164px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;
  const theme = useTheme();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  
    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);
  const [isSizeSelectedForAllItems, setIsSizeSelectedForAllItems] = useState(
    false
  );

  useEffect(() => {
    setIsSizeSelectedForAllItems(
      cart.every((item) => item.size && item.size !== "")
    );
  }, [cart]);

  const handleSizeChange = (event, item) => {
    const newSize = event.target.value;
    dispatch(updateItemSize({ id: item.id, newSize }));
  };

  const handleCheckoutButtonClick = () => {
    if (!isLoggedIn) {
      alert("Please make an account or sign in to proceed.");
      return;
    }
  
    if (cart.length === 0) {
      setShowEmptyCartWarning(true);
      return;
    }
  
    if (isSizeSelectedForAllItems) {
      setShowSizeWarning(false);
      setShowEmptyCartWarning(false);
      navigate("/checkout");
      dispatch(setIsCartOpen({}));
    } else {
      setShowSizeWarning(true);
    }
  };

  useEffect(() => {
    if (isSizeSelectedForAllItems || !isCartOpen) {
      setShowSizeWarning(false);
    }
  }, [isSizeSelectedForAllItems, isCartOpen]);

  useEffect(() => {
    if (cart.length > 0 || !isCartOpen) {
      setShowEmptyCartWarning(false);
    }
  }, [cart, isCartOpen]);

  const totalPrice = cart.reduce((total, item) => {
    return total + item.count * item.attributes.price;
  }, 0);

  return (
    <Box
      display={isCartOpen ? "block" : "none"}
      backgroundColor="rgba(0, 0, 0, 0.4)"
      position="fixed"
      zIndex={10}
      width="100%"
      height="100%"
      left="0"
      top="0"
      overflow="auto"
    >
      <Box
        position="fixed"
        right="0"
        bottom="0"
        width="max(400px, 30%)"
        height="100%"
        backgroundColor={theme.palette.mode === "dark" ? grey[900] : "white"}
        style={{ paddingTop: ".6in" }} // Add this line
      >
        <Box padding="30px" overflow="auto" height="100%">
          {/* HEADER */}
          <FlexBox mb="15px">
            <Typography variant="h3">CART ({cart.length})</Typography>
            <IconButton
              color={theme.palette.mode === "dark" ? "inherit" : "default"}
              onClick={() => dispatch(setIsCartOpen({}))}
            >
              <CloseIcon />
            </IconButton>
          </FlexBox>
          {cart.length === 0 && (
            <Typography
              textAlign="left"
              mt="10px"
              fontStyle="none"
              fontSize="15px"
              color="error.main"
            >
              There are no items in the cart.
            </Typography>
          )}

          {/* CART LIST */}
          <Box>
            {cart.map((item) => (
              <Box key={`${item.attributes.name}-${item.id}`}>
                <FlexBox p="0px 0">
                  <Box flex="1 1 40%">
                    <Link
                      to={`/item/${item.id}`}
                      onClick={() => dispatch(setIsCartOpen(false))}
                    >
                      <ItemImage
                        alt={item?.name}
                        src={`https://strapi8ball123anim888-production.up.railway.app${item?.attributes?.image?.data?.attributes?.formats?.medium?.url}`}
                      />
                    </Link>
                  </Box>
                  <Box flex="1 1 60%">
                    <FlexBox mb="5px">
                      <Typography fontWeight="bold">
                        {item.attributes.name}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          dispatch(removeFromCart({ id: item.id }))
                        }
                        color={theme.palette.mode === "dark" ? "inherit" : "default"}
                      >
                        <CloseIcon />
                      </IconButton>
                    </FlexBox>
                    <Typography>{item.attributes.shortDescription}</Typography>
                    <FlexBox m="15px 0">
                    <Box
                      display="flex"
                      alignItems="center"
                      border={`1.5px solid ${shades.neutral[500]}`}
                      mr="10px"
                    >
                      <IconButton
                        onClick={() =>
                          dispatch(decreaseCount({ id: item.id }))
                        }
                        color={theme.palette.mode === "dark" ? "inherit" : "default"}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography>{item.count}</Typography>
                      <IconButton
                        onClick={() =>
                          dispatch(increaseCount({ id: item.id }))
                        }
                        color={theme.palette.mode === "dark" ? "inherit" : "default"}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                      <Typography fontWeight="bold">
                        ${item.attributes.price}
                      </Typography>
                    </FlexBox>
                    <Typography mb="20px">
                      Size:&nbsp;
                      <Select
                        value={item.size}
                        onChange={(e) => handleSizeChange(e, item)}
                        sx={{
                          // Add this line:
                          width: "fit-content",

                          display: "flex",
                          alignItems: "center",
                          minWidth: "64px",
                          padding: "0",
                          fontSize: "inherit",
                          fontWeight: "inherit",
                          p: "0 4px",
                          lineHeight: "20px", // Decrease the lineHeight
                          height: "40px", // Add a fixed height

                          // Add the following lines to change the arrow color in dark mode:
                          '& .MuiSelect-icon': {
                            color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                          },
                        }}
                      >
                        {/* Add your available sizes as menu items */}
                        <MenuItem value="S">S</MenuItem>
                        <MenuItem value="M">M</MenuItem>
                        <MenuItem value="L">L</MenuItem>
                        <MenuItem value="XL">XL</MenuItem>
                      </Select>
                    </Typography>
                  </Box>
                </FlexBox>
                <Divider />
              </Box>
            ))}
          </Box>

          {/* ACTIONS */}
          <Box m="20px 0">
            <FlexBox m="20px 0">
              <Typography fontWeight="bold">SUBTOTAL</Typography>
              <Typography fontWeight="bold">${totalPrice}</Typography>
            </FlexBox>
            {showEmptyCartWarning && (
              <Typography
                color="error.main"
                fontWeight="bold"
                textAlign="center"
                mb="10px"
              >
                Please add an item to cart.
              </Typography>
            )}
            {showSizeWarning && (
              <Typography
                color="error.main"
                fontWeight="bold"
                textAlign="center"
                mb="10px"
              >
                Please pick a size for all items before proceeding to checkout.
              </Typography>
            )}
            <Button
              sx={{
                backgroundColor: shades.primary[400],
                color: "white",
                borderRadius: 0,
                minWidth: "100%",
                padding: "20px 40px",
                m: "20px 0",
              }}
              onClick={handleCheckoutButtonClick}
            >
              CHECKOUT
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CartMenu;