import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useTheme } from "@mui/system";
import { grey } from "@mui/material/colors";

function Profile() {
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      if (user) {
        const db = getFirestore();
        const ordersRef = collection(db, "orders");
        const userOrdersQuery = query(ordersRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(userOrdersQuery);

        const userOrders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          products: doc.data().products,
        }));

        setOrders(userOrders);
        console.log("User Orders:", userOrders);
      }
    };

    fetchOrders();
  }, [user]);

  const handlePasswordReset = () => {
    navigate("/profilereset");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      padding="2rem"
      sx={{
        gap: "2rem",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? grey[800] : "#f5f5f5",
              padding: "1.5rem",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h4" gutterBottom>
              User Info
            </Typography>
            {user && (
              <>
                <Typography variant="h6" gutterBottom>
                  Email: {user.email}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePasswordReset}
                >
                  Reset Password
                </Button>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? grey[800] : "#f5f5f5",
              padding: "1.5rem",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h4" mt={2} mb={2}>
              Your Orders:
            </Typography>
            {orders.length === 0 ? (
              <Typography>You have made no orders yet.</Typography>
            ) : (
              <TableContainer component={Paper} sx={{ maxHeight: "30vh" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Order ID</TableCell>
                      <TableCell>Items</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Size</TableCell>
                      <TableCell>Order Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) => (
                      <React.Fragment key={order.id}>
                        {order.products.map((product, index) => (
                          <TableRow key={index}>
                            {index === 0 && (
                              <TableCell rowSpan={order.products.length}>
                                {order.id}
                              </TableCell>
                            )}
                            <TableCell>{product.name}</TableCell>
                            <TableCell>${product.price}</TableCell>
                            <TableCell>{product.size}</TableCell>
                            {index === 0 && (
                              <TableCell rowSpan={order.products.length}>
                                ${order.total}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Profile;