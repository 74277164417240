import { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Box, Typography, useTheme, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { shades } from "../theme";
import { addToCart } from "../state";
import { useNavigate } from "react-router-dom";

const Item = ({ item, width }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  const { category, price, name, image } = item.attributes;
  const {
    data: {
      attributes: {
        formats: {
          medium: { url },
        },
      },
    },
  } = image;

  return (
    <Box width={width}>
      <Box
        position="relative"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        className="item"
      >
      <img
        alt={item.name}
        width="300px"
        height="400px"
        src={`https://strapi8ball123anim888-production.up.railway.app${url}`}

        onClick={() => navigate(`/item/${item.id}`)}
        style={{
          borderRadius: "10px",
          cursor: "pointer",
          backgroundColor: theme.palette.mode === "dark"
            ? "#212121" 
            : "rgba(128, 128, 128, 0.5)", 
        }}
      />
        <Box
          display={isHovered ? "block" : "none"}
          position="absolute"
          bottom="10%"
          left="0"
          width="100%"
          padding="0 5%"
        >
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="center"
              backgroundColor="black"
              borderRadius="10px"
            >
              <IconButton onClick={() => setCount(Math.max(count - 1, 1))} sx={{ color: shades.neutral[100] }}>
                <RemoveIcon />
              </IconButton>
              <Typography color={shades.neutral[100]}>{count}</Typography>
              <IconButton onClick={() => setCount(count + 1)} sx={{ color: shades.neutral[100] }}>
                <AddIcon />
              </IconButton>
            </Box>
            <Button
              onClick={() => {
                dispatch(addToCart({ item: { ...item, count } }));
              }}
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? "#000" : "#222222", // Conditionally apply the background color
                color: "white",
                borderRadius: 3,
                minWidth: "150px",
                padding: "10px 40px",
                transition: "0.3s",
                transformOrigin: "center",
                "&:hover": {
                  backgroundColor: theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.1)", // Conditionally apply the hover color
                  transform: "scale(1.05)",
                },
              }}
            >
              Add to Cart
            </Button>
          </Box>
        </Box>
      </Box>

      <Box mt="20px">
        <Typography variant="subtitle2" fontWeight="bold" color={"#fffff"} marginBottom="2px">
          {category
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase())}
        </Typography>
        <Typography marginBottom="2px">{name}</Typography>
        <Typography marginBottom="2px">${price}</Typography>
      </Box>
    </Box>
  );
};

export default Item;