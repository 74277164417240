import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/strapi-images/brand-logo.png';
import { Badge, Box, IconButton, Menu, MenuItem, Switch } from '@mui/material';
import { PersonOutline, ShoppingCartOutlined, AccountBox } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { setIsCartOpen } from '../../state';
import { useEffect, useState } from 'react';
import { auth } from '../../../src/firebase';
import { useTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";

function Navbar({ onToggleDarkMode, darkMode }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cartMenuOpen, setCartMenuOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    if (cartMenuOpen) {
      dispatch(setIsCartOpen(false));
      setCartMenuOpen(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        setUser(null);
        navigate('/');
        window.location.reload();
      })
      .catch((error) => console.error('Logout error:', error.message));
    handleClose();
  };

  const handleCartMenuToggle = () => {
    setCartMenuOpen(!cartMenuOpen);
    if (cartMenuOpen) {
      dispatch(setIsCartOpen(false));
    } else {
      dispatch(setIsCartOpen(true));
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height="70px"
      backgroundColor={theme.palette.mode === "dark" ? grey[900] : "white"}
      color={darkMode ? "white" : "black"}
      position="sticky"
      top="0"
      left="0"
      zIndex="1000"
      sx={{
        boxShadow: theme.palette.mode === "dark" ? "0px 2px 4px rgba(255, 255, 255, 0.1)" : "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderBottom: darkMode ? "none" : "1px solid #e0e0e0",
        transition: 'background-color 0.5s ease', 
      }}
    >
      <Box width="80%" margin="auto" display="flex" justifyContent="space-between" alignItems="center">
        <Box onClick={() => navigate('/')} sx={{ '&:hover': { cursor: 'pointer' } }} color="inherit">
          <img src={logo} width="35" height="35" alt="brand logo" />
        </Box>
        <Box display="flex" justifyContent="space-between" columnGap="20px" zIndex="2">
        <Switch
          onChange={onToggleDarkMode}
          checked={darkMode}
          sx={{
            '&.Mui-checked': {
              color: theme.palette.common.white,
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: theme.palette.common.white,
            },
            '& .Mui-checked + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[300],
            },
          }}
        />
          {user ? (
            <>
              <IconButton color="inherit" onClick={handleMenu}>
                <AccountBox sx={{ fontSize: 20 }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <IconButton onClick={() => navigate('/login')} sx={{ color: 'inherit' }}>
              <PersonOutline sx={{ fontSize: 20 }} />
            </IconButton>
          )}
            <IconButton onClick={handleCartMenuToggle} sx={{ color: 'inherit' }}>
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCartOutlined sx={{ fontSize: 20 }} />
                  </Badge>
            </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Navbar;