import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDnJZHuTjEnsUQ7nTFbkLrNME78dKK4o0w",
  authDomain: "ball-fe79c.firebaseapp.com",
  projectId: "ball-fe79c",
  storageBucket: "ball-fe79c.appspot.com",
  messagingSenderId: "239092052096",
  appId: "1:239092052096:web:ebfdca36ac4b1c33b0f665",
  measurementId: "G-EH9WXX9MEL"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };