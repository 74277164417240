import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import AddressForm from "./AddressForm";

const Shipping = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const theme = useTheme();

  return (
    <Box m="30px auto">
      {/* BILLING FORM */}
      <Box>
        <Typography sx={{ mb: "15px" }} fontSize="18px">
          Billing Information
        </Typography>
        <AddressForm
          type="billingAddress"
          values={values.billingAddress}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          includeContactInfo // Add this prop
        />
      </Box>

      <Box mb="20px">
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked
            value={values.shippingAddress.isSameAddress}
            onChange={() =>
              setFieldValue(
                "shippingAddress.isSameAddress",
                !values.shippingAddress.isSameAddress
              )
            }
            sx={{
              color: theme.palette.mode === 'dark' ? theme.palette.neutral.light : theme.palette.neutral.dark,
              '&.Mui-checked': {
                color: theme.palette.mode === 'dark' ? theme.palette.neutral.light : theme.palette.neutral.dark,
              },
            }}
          />
        }
        label="Same for Shipping Address"
      />
      </Box>

      {/* SHIPPING FORM */}
      {!values.shippingAddress.isSameAddress && (
        <Box>
          <Typography sx={{ mb: "15px" }} fontSize="18px">
            Shipping Information
          </Typography>
          <AddressForm
            type="shippingAddress"
            values={values.shippingAddress}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default Shipping;