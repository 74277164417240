import React, { useState } from 'react';
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Button, Container, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

const ProfileResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent to:', email);
      setSuccess(true);
      setEmailSent(true);
    } catch (error) {
      console.error('Reset password error:', error.message);
      setError(error.message);
    }
  };

  const openEmailApp = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        {!emailSent && (
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
        )}
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        {success && (
          <Typography textAlign="center" variant="body2" color="success.main">
            Password reset email sent successfully. You can now open your email and reset your password. 
          </Typography>
        )}
        <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 3 }}>
          {!emailSent && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
          {!emailSent && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send Reset Email
            </Button>
          )}

          {emailSent && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={openEmailApp}
              sx={{ mt: 3, mb: 2 }}
            >
              Open Email App
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ProfileResetPassword;